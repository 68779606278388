export const DEFAULT_AVATAR_URL =
    "https://api.dicebear.com/8.x/initials/svg?backgroundType=gradientLinear&backgroundRotation=0,360&seed=";

export const PAGINATION_LIMIT = 10;

export const COMPANIES = [
    {
        name: "Asana",
        logo: "/assets/company-01.svg",
    },
    {
        name: "Tidal",
        logo: "/assets/company-02.svg",
    },
    {
        name: "Innovaccer",
        logo: "/assets/company-03.svg",
    },
    {
        name: "Linear",
        logo: "/assets/company-04.svg",
    },
    {
        name: "Raycast",
        logo: "/assets/company-05.svg",
    },
    {
        name: "Labelbox",
        logo: "/assets/company-06.svg",
    },
] as const;

interface BulletPoint {
    point: string;
}

interface Feature {
    img_url: string;
    img_alt: string;
    title: string;
    description: string;
    action: string;
    bulletPoint: BulletPoint[];
    btnText: string;
}

export const FEATURES: Feature[] = [
    {
        img_url:
            "https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/26034511/Global-Search-feature-in-WP-Spotlight.webp",
        img_alt: "Global Search feature in WP Spotlight",
        title: "Global Search",
        description: "Use search from anywhere, front or backend.",
        action: "/features",
        bulletPoint: [
            { point: "Frontend and backend search" },
            { point: "Accessibility ready" },
            { point: "Real-time filtering for instant results" },
            { point: "Lightning fast search" },
            { point: "Intuitive UI" },
        ],
        btnText: "Save 90+ Hours Annually",
    },
    {
        img_url:
            "https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/26040509/User-Search-in-WP-Spotlight.webp",
        img_alt: "User Search in WP Spotlight",
        title: "User Search",
        description: "Manage user info directly from WP Spotlight.",
        action: "/user-management",
        bulletPoint: [
            { point: "Search users by role" },
            { point: "Search users directly by email" },
            { point: "User profile access from search" },
            { point: "User role switching (upcoming)" },
            { point: "Delete users from search (upcoming)" },
        ],
        btnText: "Save 40+ Hours Annually",
    },
    {
        img_url:
            "https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/26040503/Plugin-control-in-WP-Spotlight.webp",
        img_alt: "Plugin control in WP Spotlight",
        title: "Plugin Control",
        description: "Update plugins from search",
        action: "/plugin-management",
        bulletPoint: [
            { point: "Access plugin repository from search" },
            { point: "View plugin WP org page" },
            { point: "Bulk update all plugins" },
            { point: "Update plugins individually" },
            { point: "Deactivate and delete plugins" },
        ],
        btnText: "Save 60+ Hours Annually",
    },
    {
        img_url:
            "https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/26040506/Theme-control-in-WP-Spotlight.webp",
        img_alt: "Theme control in WP Spotlight",
        title: "Theme Control",
        description: "Manage themes from search",
        action: "/plugin-management",
        bulletPoint: [
            { point: "Access theme repository from search" },
            { point: "Switch themes from search" },
            { point: "Delete themes from search" },
            { point: "Update themes from search" },
            { point: "Save time on theme management" },
        ],
        btnText: "Save 25+ Hours Annually",
    },
    {
        img_url:
            "https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/26040457/Media-actions-in-WP-Spotlight.webp",
        img_alt: "Media actions in WP Spotlight",
        title: "Media Actions",
        description: "Manage media in search",
        action: "/media-management",
        bulletPoint: [
            { point: "Insert media into posts from search" },
            { point: "Edit media from search" },
            { point: "Upload media into search" },
            { point: "Delete media from search" },
            { point: "Download media from search" },
        ],
        btnText: "Save 55+ Hours Annually",
    },
    {
        img_url:
            "https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/26040500/Mutisite-actions-in-WP-Spotlight.webp",
        img_alt: "Mutisite actions in WP Spotlight",
        title: "Multisite Actions",
        description: "Manage multisite network from search",
        action: "/multisite-management",
        bulletPoint: [
            { point: "Automatic detection of multisite" },
            { point: "Seamless and fast site switching" },
            { point: "Full sub-site list on search" },
            { point: "Time Instant network admin access" },
            { point: "Save time on multisite management" },
        ],
        btnText: "Save 100+ Hours Annually",
    },
];

export const ALLFEATURES = [
    {
        title: " User Management with Role-based Access",
        description:
            "Quickly access and edit user details without navigating through multiple screens.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Search by Role: View users by role (Admin, Author, Subscriber) instantly.",
            },
            {
                point: "Direct Email Search: Type an email to see all roles for that user.",
            },
            {
                point: "Edit Profiles: Jump directly to user profiles for quick edits.",
            },
        ],
    },
    {
        title: "Streamlined Plugin Management with WP-Admin Navigation",
        description: "Manage your plugins without leaving WP Spotlight.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Find & Install: Search and install plugins fast directly from the WordPress repository.",
            },
            {
                point: "One-Click Updates: Update plugins with one-click operations for productivity.",
            },
            {
                point: "Instant Plugin Access: Instantly search and access all plugin menus present on your WordPress dashboard from anywhere",
            },
            {
                point: "Activate/Deactivate: Easily toggle plugins on or off directly from search",
            },
            {
                point: "Quick Plugin Search: Search by plugin name to view its settings without navigating away for better WordPress workflow Optimization.",
            },
        ],
    },
    {
        title: "Instant Site Navigation for Theme Management",
        description: "Easily find, activate, and remove themes in seconds.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Access Repository: Makes searching and installing themes fast and effortless",
            },
            {
                point: "Quick Activation: Doubles as an efficiency tool plugin for quick theme management.",
            },
            {
                point: "Safe Deletion: Remove any theme except the currently active one.",
            },
        ],
    },
    {
        title: "CPT Search and Content Management",
        description:
            "Access all your posts and pages without extra clicks. WP Spotlight lets you view CPT Search results and manage content easily.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "View All Post Types: See every post type on your site in one list, with custom post types included.",
            },
            {
                point: "Quick Actions: Edit, view, or delete posts directly from WP Spotlight as part of its Admin Command Search capabilities.",
            },
        ],
    },
    {
        title: "Create Content Instantly",
        description:
            "Skip the usual steps and get right to creating with WP Spotlight’s direct content creation shortcuts. Select the “Create” option, choose your post type, and start adding new content instantly.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Choose Post Type: Select from your site’s post types and jump directly to the new post editor, supported by Global Site Search.",
            },
            {
                point: "One-Step Creation: Save time with Custom Commands Plugin by choosing a post type and jumping directly to the editor.",
            },
        ],
    },
    {
        title: "Simple Media Management",
        description: "Manage all your media from a single search bar.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Find Files Fast: Locate specific files and access editing options.",
            },
            {
                point: "Upload, Delete, Download: Add, remove, or download media in seconds.",
            },
        ],
    },
    {
        title: "Personalized Key Binds",
        description: "Customize your workflow by setting shortcuts for any action or search.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Custom Shortcuts: Assign key binds for all WP Spotlight features. ",
            },
            {
                point: "Quick Access: Start searches or actions with a single keystroke.",
            },
        ],
    },
] as const;

export const REVIEWS = [
    {
        name: "Collins Agbonghama",
        // username: "@michaelsmith",
        designation: "WordPress business owner",
        avatar: "/assets/collins-agbonghama.webp",
        // rating: 5,
        review: "The simplicity of WP Spotlight is what makes it so powerful. Instead of clicking through endless menus, I can find users, manage media, and even create new posts in seconds. It’s the kind of tool that once you get hooked on, you can’t live without.",
    },
    {
        name: "Luca Piccinotti",
        // username: "@emilyjohnson",
        designation: "CEO, Studio Piccinotti",
        avatar: "/assets/luca-piccinotti.webp",
        // rating: 5,
        review: "We’ve tried plenty of admin search tools on WordPress, but WP Spotlight stands out because it’s both lightweight and fast. The custom keyboard shortcuts alone have the potential to save you hours of extra work a month.",
    },
    {
        name: "Jakir Hasan",
        // username: "@emilyjohnson",
        designation: "Co-founder & CEO at WPXPO",
        avatar: "/assets/jakir-hasan.webp",
        // rating: 5,
        review: "WP Spotlight is one of those tools that you only miss once it’s not there. You use it for a bit and realize how did I ever manage my site without this. The jump in productivity is like going from walking to a jet engine. A must have for any WordPress user!",
    },
] as const;
